.profile-box{
    display: flex;
    background: #FFFFFF;
    box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 40px;
}

.profile-box .display-photo{
    padding-right: 40px;
    border-right : 1px solid #E6E4E8;
    min-width: 15%;
}

.profile-box .display-photo img{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    margin-bottom: 24px;
}

.profile-box .display-photo button{
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    padding: 12px 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    margin-left: 10px;
}

.profile-box .user-data{
    min-width: 85%;
    padding-left: 40px;
    display: flex;
}

.profile-box .user-data input, .profile-box .user-data select{
    height : 52px;
}

.profile-box .user-data input#phoneNumber::placeholder{
    content : "XX";
}

.profile-box .user-data button{
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    padding: 12px 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    float: right;
    height: 52px;
}

.notification-setting-list{
    list-style : none;
    padding-left: 0;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #7D7C7F;
}

.reauth-modal .modal-body{
    height : auto !important;
    border: none;
}

.reauth-modal .modal-dialog{
    max-width: 650px;
    z-index: 1050;
}

.reauth-modal .modal-content{
    border: none;
    padding: 3rem 3rem 3rem 3rem !important;
}

.password-change-modal .modal-body{
    height : auto !important;
    border: none;
}

.password-change-modal .modal-dialog{
    max-width: 650px;
    z-index: 1050;
}

.password-change-modal .modal-content{
    border: none;
    padding: 1.75rem 1.75rem 3rem 3rem !important;
}