.navbar-logo{
    height: 100%;
}

.navbar{
    width: 100%;
    height: 76px;
    padding: 16px 40px !important;
    background: #FFFFFF;
    /* Menu */

    box-shadow: 0px 4px 20px rgba(43, 43, 43, 0.08);

    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
}

#navbarNav{
    background : #ffffff !important;
}

.navbar-toggler .line{
    width: 20px;
    display: block;
    height: 2px;
    background-color: #f7f7f7;
    margin-bottom: 5px;

}

.header-nav-item{
    margin-right: 40px;
}

.header-nav-link{
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #414042 !important;
}

.btn-login{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* green/50 */

    color: #22C9C7 !important;
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
}

.btn-create-task-nav{

    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC !important;
    background: #22C9C7 !important;
    border-radius: 8px;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.btn-signup{

    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* gray/10 */

    color: #FCFCFC !important;
    /* green/50 */

    background: #22C9C7 !important;
    border-radius: 8px;
}

.avatar{
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.custom-dropdown-menu{
    left: -125px;
    top: 115% !important;
    box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.08);
}

.custom-dropdown-menu li{
    margin-left: 10px;
    padding: 5px;
}

.custom-dropdown-menu li.divider{
    border-top: 1px solid #7D7C7F;
    margin-right: 10px;
}

.custom-dropdown-menu li a{
    color: #7D7C7F !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none !important;
}

.badge-count{
    color: #FFFFFF;
    background : #22C9C7;
    border-radius: 100%;
    padding: 5px 10px;
    font-size: 11px;
    margin-left: 8px;
    font-weight: bold;
}