.users-search{
    position: relative;
    width: 292px;
    flex: 1;
    float: right;
}

.users-search img{
    position: absolute;
    left: 20px;
    top: 13px;
    height: 15px;
    width: 15px;

}

.users-search-input{
    background: #FFFFFF;
    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
    padding: 16px 16px 16px 42px;
    height: 42px;
    width: 292px;
}

.users-search-input::placeholder{
    font-weight: 300;
}

.view-email-verification-modal .modal-body img{
    margin: 0 auto;
    text-align : center;
}

.view-email-verification-modal .modal-body{
    height : auto !important;
    border: none;
    text-align: left;
}

.view-email-verification-modal .modal-dialog{
    max-width: 600px;
}

.view-email-verification-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.view-email-verification-modal .modal-body .ok{
    width: 200px;
    background: #22C9C7;
    border-radius: 8px;
    display: block;
    text-align: center;
    margin: 0 auto;
    text-decoration: none !important;
    color: white !important;
    padding: 16px 20px;
    margin-top: 50px;
}

.verification-header{
    font-size: 30px;
    font-weight : bold;
}

.verification-body{
    white-space : pre-line;
}

.invite-box-display-photo{
    min-width: 15%;
    padding-bottom : 15px;
    text-align: center;
}

.invite-box-display-photo img{
    width: 80%;
    height: 80%;
    border-radius: 100%;
    margin: 24px auto;
}

.invite-box-display-photo button{
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    padding: 12px 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    margin: 10px auto;
    width: 30%
}