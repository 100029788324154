.rm-translator-item{
    padding: 24px 26px;
    height : auto;
}

.rm-translator-main-details{
    position: relative;
}

.rm-translator-main-details .main-details{
    margin-left: 75px;
}

.rm-translator-main-details .display-photo{
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
}

.rm-translator-main-details .tr-rating img{
    width: 15px;
    height: 15px;
}

.rm-translator-main-details .tr-rating img:not(:first-child){
    margin-left: 7px;
}

.rm-translator-main-details .tr-name{
    color : #414042;
    font-size: 14px;
    display: block;
}

.rm-translator-main-details .tr-presence{
    color: #939097;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.rm-translator-main-details .tr-presence-online{
    color: #66BB6A;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.rm-translator-main-details .tr-presence-offline{
    color: #939097;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.rm-translator-sub-details{
    display : flex;
    margin-top: 35px;
}

.rm-translator-sub-details .tr-label{
    color : #939097;
    font-weight: 300;
    font-size: 12px;
    align-items: center;
    display: block;
}

.rm-translator-sub-details .tr-value{
    font-weight: bold;
    font-size: 12px;
    color: #414042;
    text-transform : capitalize;
}

.rm-task-description{
    margin-top: 24px;
    margin-bottom: 10px;
}
.rm-task-description p{
    font-size: 15px;
    word-break: break-word;
}

.rm-task-description p:before{
    content : "Description: "
}
