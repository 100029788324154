.chat-main{
    display: flex;
}

.chat-main .user-list{
    flex: 1;
    min-width: 30%;
    background: #FFFFFF;
    box-shadow: 16px 16px 72px rgba(32, 32, 32, 0.08);
    border-radius: 8px;
    height: calc( 100vh - 176px);
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    overflow-y: auto;
}

.chat-main .conversation-box{
    margin-left: 32px;
    flex: 4;
    box-shadow: 16px 16px 72px rgba(32, 32, 32, 0.08);
    border-radius: 8px;
    height: calc( 100vh - 176px);
} 

/* CHAT BOX TABS */
.chat-nav {
    margin-right: 20px !important;
    margin-left: 20px !important;
}
.chat-nav .nav-link.active{
    background: #22C9C7 !important;
    color: white !important;
    border : none !important;
    font-size: 12px;
}

.chat-nav .nav-link:not(.active){
    color: #7D7C7F !important;
    border : none !important;
    font-size: 12px;
}

/* CHAT TABS CONTENT */
.chat-tab-content{
    height: 100%;
}

.chat-tab-content .tab-pane{
    position: relative;
    overflow-y: auto;
    height: calc(100% - 20px);
}


.conv-list-tabs{
    overflow: auto;
    width: 100%;
}

/* CHAT BOX USER SEARCH */
.chat-search{
    position: relative;
    width: 100%;
    flex: 1;
    padding: 20px;
}

.chat-search img{
    position: absolute;
    left: 33px;
    top: 33px;
    height: 15px;
    width: 15px;
}

.chat-search-input{
    background: #FFFFFF;
    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
    padding: 16px 16px 16px 42px;
    height: 42px;
    width: 100%;
}

.chat-search-input::placeholder{
    font-weight: 300;
}

.chat-item{
    height: 65px;
    margin-bottom: 8px;
    cursor: pointer;
    padding-top: 10px;
}

.chat-item.selected{
    background: #F5F4F6;
}

.chat-item-body{
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}

.chat-item-body .display-photo{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
}

.chat-item-body .details{
    margin-left: 60px;
    position: relative;
}

.chat-item-body .details .username{
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    color: #3F3F3F;
    display: block;
    font-weight: bold;
}

.chat-item-body .details .task-title{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #464646;
}

.chat-item-body .details .highlighted-text-result{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #464646;
    overflow : hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat-item-body .details .chat-date{
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #BDBDBD;
}

/* CONVERSATION BOX */
.conversation-box{
    padding: 16px 20px;
    position: relative;
}

.chat-dropdown-menu{
    left: calc(100% - 50px) !important;
    top: 20% !important;
    box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.08);
}

.chat-dropdown-menu li{
    margin-left: 10px;
    padding: 5px;
}

.chat-dropdown-menu li.divider{
    border-top: 1px solid #7D7C7F;
    margin-right: 10px;
}

.chat-dropdown-menu li a{
    color: #7D7C7F !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none !important;
}

.conversation-header{
    position: relative;
    border-bottom: 1px solid #E6E4E8;
    padding-bottom: 24px;
    max-height: 70px;
    min-height: 70px;
}

.conversation-header .recipient-details .details{
    margin-left: 60px;
    position: relative;
}

.conversation-header .recipient-details .display-photo{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
}

.conversation-header .recipient-details .details .username{
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #3F3F3F;
    display: block;
}

.conversation-header .recipient-details .details .task-title{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #464646;
}

.conversation-header .recipient-actions{
    position: absolute;
    right: 0;
    top: 10px;
}

.conversation-header .recipient-actions a#imageDropdown{
    text-decoration: none;
    color: #22C9C7;
}

.conversation-header .recipient-actions a#imageDropdown:hover{
    /* text-decoration: none;
    color: white;
    background: #22C9C7;
    border-radius: 4px;
    padding: 6px 4px 10px 4px; */
}

.conversation-body{
    max-height: calc(100% - 120px);
    height: calc(100% - 120px);
    overflow-y: auto;
    position: relative;
}

.conversation-body .empty-message{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #909090;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 15%;
}

.chat-file-list{
    margin-bottom: 0 !important;
}

.messages-header{
    display: flex;
    margin-top: 10px;
}

.messages-header p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
    margin: 0 auto;
    width: 600px;
    text-align: center;
    z-index: 10;
}

.message-header-border{
    width: 100%;
    margin-top: 10px;
    height: 1px;
    border-bottom: 1px solid #EDEDED;
}

.message-details{
    width: inherit;
}

.conversation-footer{
    padding: 10px;
    max-height: 70px;
    height: 65px;
    border-top: 1px solid #E6E4E8;
}

.conversation-footer .message-container{
    position: relative;
    width: 100%;
    flex: 1;
}

.conversation-footer .message-container .message-attach{
    position: absolute;
    left: 0;
    top: 10px;
    height: 21px;
    width: 18px;
    cursor : pointer;
}

.conversation-footer .message-container .message-send{
    position: absolute;
    right: 0;
    top: 10px;
    height: 21px;
    width: 18px;
    cursor : pointer;
}

.conversation-footer .message-container .message-send-input{
    background: #FFFFFF;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
    padding: 8px 42px 16px 42px;
    height: 42px;
    width: 100%;
    resize: none;
    overflow-y: hidden;
    color: #414042;
}

.conversation-footer .message-container .message-send-input:focus{
    border: none;
    outline: none;
}

.conversation-footer .chat-message-files{
    list-style : none;
    padding-left: 0;
    position: absolute;
    width: 95.5%;
    background: white;
    bottom: 66px;
    padding: 10px;
}

.conversation-footer .chat-message-file{
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none !important;
}

.conversation-footer .chat-message-file a{
    text-decoration: none !important;
}

.chat-search-input::placeholder{
    font-weight: 300;
}