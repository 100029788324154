.view-btn{
    background: #F5F4F6;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding: 16px 20px;
    border: none;
    height: 50px;
    margin-right : 10px;
}

.save-btn{
    width: 110px;
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 12px 10px;
    height : 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
}