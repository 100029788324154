.task-table-div{
    background: #FFFFFF;
    box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.task-table-div table{
    width: 100%;
}

.task-table-div table th{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #939097;
    padding: 15px;
    background : #ffffff;
}


.task-table-div table tr td{
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #080708; 
    padding: 15px;
    vertical-align: top;
}

.task-table-div table tr{
    cursor: pointer;
}
.task-table-div table tr:nth-child(odd){
    background: #FAFAFB;
}

.task-type{
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 4px 16px;
    display: block;
    width: 8rem;
}

.task-price{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #414042;
}

.tasks-search{
    position: relative;
    width: 292px;
    flex: 1;
    float: right;
}

.tasks-search img{
    position: absolute;
    left: 20px;
    top: 18px;
    height: 15px;
    width: 15px;

}

.tasks-search-input{
    background: #FFFFFF;
    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
    padding: 18px 18px 18px 42px;
    height: 52px;
    width: 292px;
}

.tasks-search-input::placeholder{
    font-weight: 300;
}

.date-range-filter{
    display: flex;
    background: transparent;
    /* gray/40 */

    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    height: 52px;
    padding: 10px;
    margin-left: 24px;
    font-size: 16px;
    line-height: 28px;
    align-items: center;
    color: #414042;
}

.date-range-filter input{
    height: 20px;
    width: 88px !important;
    margin-left: 10px;
    background: #ffffff;
    color: #414042;
    padding-left: 0 !important;
    font-size: 12px;
    text-indent: 10px;
    text-align: center;
}

.filter-tasks{
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding: 8px 20px;
    background: transparent;
    height: 52px;
    margin-left: 20px;
}

.task-dropdown-filter{
    position: relative;
    z-index: 100;
}
.task-floating-filter{
    width: auto;
    max-width: 900px;
    background : white;
    padding : 24px 24px 24px 0;
    left: -625px;
    position: absolute;
    top: 60px;
    box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
}
.task-floating-filter .filter-options-header{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #080708;
    margin-bottom: 30px;
    display: block;
}
.task-floating-filter .filter-option-list{
    list-style : none;
    padding-left: 0;
}
.task-floating-filter .filter-option-list li label{
    font-size: 11px !important;
}
.task-floating-filter .filters{
    display: flex;
}
.task-floating-filter .nonflex-filters{
    display: block;
}
.task-floating-filter .filter-options{
    margin-left: 38px;
    min-width: 200px;
}