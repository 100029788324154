.empty-task-heading-text{
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    color:#414042 ;
    margin-bottom: 15px;
}

.empty-task-heading-sub{
    display: block;
    margin-bottom: 45px;
    color : #414042;
}

.empty-task-heading-button{
    border-radius: 8px;
    width: 192px;
    display: block;
    text-align: center;
    height: 52px;
    background: #22C9C7;
    border: none;
    text-transform: uppercase;
    font-size: 16px;
    color: white !important;
    text-decoration : none !important;
    padding: 16px 20px;
}


.empty-assigned-task-heading-button{
    border-radius: 8px;
    width: 264px;
    display: block;
    text-align: center;
    height: 52px;
    color: #22C9C7;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    background: white !important;
    padding: 16px 20px;
    border: 2px solid #22C9C7;
}


.load-more-button{
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding: 16px 20px;
    background : #FFF;
    margin: 0 auto;
}

.task-item{
    width: 100%;
    min-height: 200px;
    height: auto;
    background: #FAFAFB;
    border-radius: 12px;
    margin-bottom: 10px;
    padding: 18px 12px;
}


.task-item .field-label{
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;    
}

.task-item .field-value{
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #414042;
}

.task-item .item-panel{
    display: flex;
}

.task-item .task-status{
    font-weight: 500;
    font-size: .80rem;
    line-height: 28px;
    color: #293846;
}

.task-item .task-type{
    
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 4px 16px;
    display: block;
    width: 8rem;
}

.task-item .task-title{
    font-weight: 600;
    font-size: .90rem;
    line-height: 28px;
    color: #464646;
}

.task-item .task-description,
.task-files .task-description{
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #676A6C;
    word-break: break-all;
}

.task-item .task-payed{
    font-weight: 600;
    font-size: .95rem;
    line-height: 28px;
    color: #464646;
    margin-bottom: 0;
}

.task-item .task-remaining-amount{
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #464646;
}

.task-pay-left{
    background: #22C9C7;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    width: 90%;
    padding: 12px 16px;
    border: none;
    margin-top: 35px;
    position: absolute;
    bottom: 0;
}

.task-pay-left-details{
    background: #22C9C7;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC !important;
    width: 100%;
    padding: 12px 16px;
    border: none;
    display: block;
    text-decoration: none !important;
}


.task-give-feedback{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    width: 100%;
    padding: 12px 16px;
    border: none;
    margin-top: 35px;
    background: #FAFAFB;
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
}

.task-item-delete{
    border: 2px solid #FF6666;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FF6666;
    background: #FAFAFB;
    padding: 14px 16px;
    width: 116px;
    margin-bottom: 20px;
}

.task-item-delete img{
    float: left;
}

.task-item-edit{
    border: 2px solid #FF8E26;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FF8E26;
    background: #FAFAFB;
    padding: 14px 16px;
    width: 116px;
    margin-bottom: 20px;
    text-align: center;
}

.task-item-edit img{
    float: left;
}

.task-item-pay{
    background: #22C9C7;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    width: 116px;
    padding: 14px 16px;
    border: none;
}

.delete-task-modal .modal-body{
    height : 200px !important;
    border: none;
}

.delete-task-modal .modal-content{
    border: none;
}

.pause-task-modal .modal-body{
    height : 500px !important;
    border: none;
}

.pause-task-modal .modal-dialog{
    max-width: 650px;
}

.pause-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.stop-task-modal .modal-body{
    height : 580px !important;
    border: none;
}

.stop-task-modal .modal-dialog{
    max-width: 650px;
}


.stop-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.escalate-task-modal .modal-body{
    height : 400px !important;
    border: none;
}

.escalate-task-modal .modal-dialog{
    max-width: 650px;
}


.escalate-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}


.payment-confirmation-modal .modal-dialog{
    max-width: 800px;
}

.payment-confirmation-modal .modal-body{
    height: 250px !important;
}

.payment-confirmation-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.notify-account-manager-modal .modal-body{
    height : 250px !important;
    border: none;
}

.notify-account-manager-modal .modal-dialog{
    max-width: 550px;
}


.notify-account-manager-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.accepted-task-modal{
    padding: 0;
}

.accepted-task-modal .modal-body{
    height : 450px !important;
    border: none;
}

.accepted-task-modal .modal-dialog{
    max-width: 640px;
}


.accepted-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}


.accepted-task-details{
    text-align: left;
    margin-top: 32px;
    background: none !important;
    padding: 24px 40px;
}


.reason-list{
    list-style : none;
    padding-left: 0;
    text-align: left;
    margin-left: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #414042;
}

ul.status-filters{
    display: flex;
    flex-direction: row;
    list-style: none;
    font-size: .75rem;
}

.status-filter{
    border-radius: 28px;
    width: 104px;
    height: 36px;
    background: #F5F4F6;
    border: 1px solid #F5F4F6;
    box-sizing: border-box;
    color : #7D7C7F;
    padding: 8px 16px;
    display: block;
    cursor: pointer;
}

ul.type-filters{
    display: flex;
    flex-direction: row;
    list-style: none;
    font-size: 12px;
    padding-left: 0;
}

.type-filter{
    border-radius: 28px;
    width: 120px;
    height: 36px;
    background: #F5F4F6;
    border: 1px solid #F5F4F6;
    box-sizing: border-box;
    color : #7D7C7F;
    padding: 8px 16px;
    display: block;
    text-align: center;
    cursor: pointer;
}

.custom-task-dropdown{
    transform: translate3d(-85px, 21px, 0px);
    left: -60px !important;
    top: 35% !important;
    box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.08);
}

.custom-task-dropdown li{
    margin-left: 10px;
    padding: 5px;
}

.custom-task-dropdown li.divider{
    border-top: 1px solid #7D7C7F;
    margin-right: 10px;
}

.custom-task-dropdown li a{
    color: #7D7C7F !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none !important;
}

.custom-dropdown a{
    text-decoration : none !important;
    color : #22C9C7 !important;
}

.filter-no-task{
    padding-top: 50px;
    text-align: center;
}

.filter-no-task img{
    width: 30%;
    height: 30%;
}

.filter-no-task-text{
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #414042;
}

.filter-no-task-button{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    background: #22C9C7;
    border-radius: 8px;
    padding: 16px 20px;
    border: none;
}

.create-task-step-header{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: block;
}

.create-task-step-header.active{
    color: #293846 !important;
}

.create-task-step-header.inactive{
    color: #BDBDBD !important;
}

.create-checklist{
    background: #FFFFFF;
    box-shadow: 12px 16px 52px rgba(29, 48, 0, 0.07);
    border-radius: 8px;
    height: 170px;
    padding: 24px;
    font-size: 20px;
    line-height: 28px;
    color: #464646;
}

.task-checklist{
    list-style: none;
    padding-left: 0;
}

.task-checklist li {
    font-size: 14px;
}

.btn-complete-order{
    width: 100%;
    background: #22C9C7;
    border-radius: 8px;
    padding: 16px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    border : none;
}

.step-1-divs{
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 172px;
    padding: 24px;
}

.step-1-option{
    background: #F5F4F6;
    border: 1px solid #F5F4F6;
    box-sizing: border-box;
    border-radius: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #7D7C7F;
    padding: 8px 16px;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.step-1-selected{
    background: #7AB7B6 !important;
    color: #FFFFFF !important;
}

.custom-create-task-label{
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #414042;
}

.create-task-upload-btn{
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding: 16px;
    background: white;
}

.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker__input-container > input{
    width: 100% !important;
    background: #FAFAFB;
    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    text-indent: 30px;
    padding: 16px;
    font-weight: 500;
}

.task-file-list{
    margin-top: 20px;
    list-style: none;
    padding-left: 0;
}

.task-file-list li {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #939097;
    padding: 8px;
}

.task-file-list li:not(:last-child){
    border-bottom: 1px solid #DFDFE0;
}

.task-file-item-icon{
    width: 16px;
    height: 24px;
    margin-right: 11px;
}

.task-file-item-remove  {
    width: 20px;
    height: 22px;
    cursor: pointer;
    float: right;
}

.additional-services-modal .modal-content{
    width: 860px !important;
    height: 740px !important;
    text-align : left !important;
    border: none !important;
}

.additional-services-modal .modal-body{
    padding: 50px !important;
}

.additional-services-modal .modal-dialog{
    margin-left: 20% !important;
}

.additional-services-modal .modal-body ul.services-list{
    list-style: none !important;
    padding-left: 0 !important;
}

.additional-services-modal .services-list li{
    padding: 24px 0;
}
.additional-services-modal .services-list li:not(:last-child){
    border-bottom: 1px solid #DFDFE0;
}

.additional-services-modal .service-item{
    display : flex;
}

.additional-services-modal .service-title{
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #414042;
    margin-bottom: 5px;
}

.additional-services-modal .service-text{
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #414042;
    width: 400px;
}

.additional-services-modal .service-item img{
    width: 52px;
    height: 52px;
    margin-top: 20px;
}

.additional-services-modal .service-item .service-price::before{
    content : "$";
}
.additional-services-modal .service-item .service-price{
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-align: left;
    margin-left: 20px;
    color: #414042;
}

.additional-services-modal .service-item .service-add-to-order{
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding: 12px 16px;
    background: white;
}

.additional-services-modal .service-item .service-add-to-order-div{
    padding-left: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7 !important;
    background: white !important;
    border: none;
}


.additional-services-modal .service-item .service-add-to-order-div img{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 0px;
}

.additional-services-modal .service-item .service-inc-translator{
    background: #22C9C7;
    border-radius: 4px;
    border: none;
    width : 36px;
    height: 36px;
    color: white;
}

.additional-services-modal .service-item .service-translator-count{
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #939097;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
}

.additional-services-modal .service-item .service-dec-translator{
    background: #F5F4F6;
    border-radius: 4px;
    border: none;
    width : 36px;
    height: 36px;
    color: white;
}

.task-coupon-input{
    background: #FAFAFB;
    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #414042;
    width: 372px;
    margin-right: 10px;
}

.task-coupon-apply{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    border: none;
    width: 96px;
}

.task-pay-with-card{
    background: #22C9C7;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding : 16px 20px;
    border: none;
    width: 232px;
    margin-right: 10px;
}

.task-pay-with-paypal{
    background: #F5F4F6;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding : 16px 20px;
    border: none;
    width: 232px;
}

.pay-task{
    background: #22C9C7;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 16px 20px;
    width: 100%;
    border: none;
}



.task-files{
    margin-right: 40px;
    margin-left: 40px;
}

.description-div{
    max-height: 200px;
    overflow-y: auto;
}

.chat-header{
    padding-left: 40px;
    padding-right: 40px;
    z-index: 100;
    height: 90px;
    background-color: white;
    box-shadow: 0px 4px 6px -6px rgba(0, 0, 0, 0.12)
}

.chat-header img{
    border-radius: 100%;
}

.chat-footer{
    z-index: 100;
    min-height: 70px;
    height : auto;
    width: 100%;
    background-color: white;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.12);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 40px;
    
}

.chat-file-list{
    list-style : none;
    padding-left: 0;
}

.chat-file-list li{
    margin-top: 5px;
}

.message-item{
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
}

.message-sender-avatar img{
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.message-details{
    width: 100%;
}

.message-bubble{
   
    font-size: 14px;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
}

.message-date{
    margin-bottom: 5px;
    display: flex;
}

.message-date span{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
}

.message-list{
    height: calc(100vh - 320px);
    overflow-y: auto;
}

.message-files{
    list-style : none;
    padding-left: 0;
}

.message-file{
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none !important;
}

.message-file a{
    text-decoration: none !important;
}


/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}