.operator-table-div{
    background: #FFFFFF;
    box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.operator-table-div table{
    width: 100%;
}

.operator-table-div table th{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #939097;
    padding: 15px;
    background : #ffffff;
}


.operator-table-div table tr td{
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #080708; 
    padding: 15px;
    vertical-align: top;
}

.operator-table-div table tr{
    cursor: pointer;
}
.operator-table-div table tr:nth-child(odd){
    background: #FAFAFB;
}

.operator-type{
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 4px 16px;
    display: block;
    width: 8rem;
}

.operator-price{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #414042;
}

.fire-operator-modal .modal-body{
    height : auto !important;
    border: none;
}

.fire-operator-modal .modal-dialog{
    max-width: 650px;
}

.fire-operator-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}