.candidate-item{
    background: #FAFAFB;
    border-radius: 12px;
    padding: 24px 24px;
    margin-bottom: 20px;
    height: auto;
    display: flex;
}

.candidate-item .candidate-item-left .main-details{
    margin-left: 75px;
}

.candidate-item .candidate-item-left .sub-details{
    margin-top: 40px;
    display : flex;
}

.candidate-item .candidate-item-left{
    flex: 1;
    position: relative;
    max-width: 25%;
    min-width: 25%;
    border-right: 1px solid #E9E9EA;
}

.candidate-item .candidate-item-left .display-photo{
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
}

.candidate-item .candidate-item-left .cd-rating img{
    width: 15px;
    height: 15px;
}

.candidate-item .candidate-item-left .cd-rating img:not(:first-child){
    margin-left: 7px;
}

.candidate-item .candidate-item-left .cd-name{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #414042;
    display: block;
    margin-top: 15px;
}

.candidate-item .candidate-item-left .cd-presence{
    color: #939097;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.candidate-item .candidate-item-left .cd-presence-offline{
    color: #939097;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.candidate-item .candidate-item-left .cd-presence-online{
    color: #66BB6A;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.candidate-item .candidate-item-right{
    margin-left: 20px;
    max-width: 50%;
    width: 50%;
    border-right: 1px solid #E9E9EA;
    padding-right: 20px;
}

.candidate-item .candidate-item-right .candidate{
    position: relative;
}

.candidate-item .candidate-item-right .candidate .actions{
    position: absolute;
    top: 0;
    right: 25px;
    display: flex;
    width: 30%;
}

.candidate-item .candidate-item-right .candidate .actions .hire-candidate{
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    width: 90%;
    color: #FCFCFC;  
    text-transform : uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
}

.candidate-item .candidate-item-right .candidate .details{
    display : flex;
    width: 70%;
}

.candidate-item .candidate-item-left .cd-label{
    color : #939097;
    font-weight: 300;
    font-size: 12px;
    align-items: center;
    display: block;
}

.candidate-item .candidate-item-left .cd-value{
    font-weight: bold;
    font-size: 12px;
    color: #414042;
    text-transform : capitalize;
}

.candidate-item .candidate-item-right .task-details{
    margin-top: 24px;
    margin-bottom: 10px;
}

.candidate-item .candidate-item-right .download-cv{
    border: 2px solid #29B6F6;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #29B6F6;
    background : #FFF;
    padding : 14px 16px;
    margin-right : 10px;
    text-decoration : none;
    display: block;
    margin-top: 20px;
    width: 185px;
}

.candidate-item .candidate-item-right .task-details p{
    font-size: 15px;
    word-break: break-word;
    color: #676A6C;
    font-weight: 400;
    font-size: 16px;
}

.candidate-item .candidate-item-right .task-details p:before{
    content : "Description: "
}

.candidate-item .candidate-actions{
    max-width: 25%;
    min-width: 25%;
    padding-left: 20px;
}

.candidate-item .candidate-actions .accept-candidate{
    background: #22C9C7;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border: none;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.candidate-item .candidate-actions .decline-candidate{
    background: transparent;
    border: 2px solid #FF6666;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FF6666;
    padding: 12px 16px;
    width: 100%;
}

.decline-candidate-modal .modal-body{
    height : auto !important;
    border: none;
}

.decline-candidate-modal .modal-dialog{
    max-width: 650px;
}

.decline-candidate-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.decline-candidate-modal .decline-candidate-header{
    font-size: 20px;
}

.decline-candidate-modal .btn-ok{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border : none;
    margin-right: 15px;
}

.decline-candidate-modal .btn-cancel{
    border: 2px solid #FF6666;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FF6666;
    padding : 12px 16px;
    background: transparent;
}

.accept-candidate-btn{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border : none;
    margin-right: 15px;
    width: 100%;
}