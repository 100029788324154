/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/montserrat-v14-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
       url('./fonts/montserrat-v14-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-100.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-100.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/montserrat-v14-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
       url('./fonts/montserrat-v14-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-200.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('./fonts/montserrat-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('./fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('./fonts/montserrat-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/montserrat-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('./fonts/montserrat-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('./fonts/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/montserrat-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
       url('./fonts/montserrat-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-800.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/montserrat-v14-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Black'), local('Montserrat-Black'),
       url('./fonts/montserrat-v14-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

html{
  scroll-behavior: smooth;
  height: 100%;

}

body {
  margin: 0;
  font-family: Montserrat !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: calc(100vh - 100px);
}

#root{
  height: 100%;
  position: relative;
}

.app{
  height: 100%;
}

.content{
  margin-top: 90px;
  min-height: calc(100vh - 100px);
  height: auto;
  padding: 16px 40px;
  /* Menu */

  box-sizing:border-box;  /** add this **/
 -moz-box-sizing:border-box; /** add this **/
 -webkit-box-sizing:border-box; /** add this **/
 -ms-box-sizing:border-box; /** add this **/
}

.div-holder{
  height: 100%;
}

.block{
  display: block !important;
}

::-webkit-input-placeholder { /* Edge */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* gray/60 */

  color: #939097;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* gray/60 */

  color: #939097;
}

::placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* gray/60 */

  color: #939097;
}

.custom-label{
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #414042;
}

.custom-input{
  background: #FAFAFB;
  border: 1px solid #E6E4E8;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 8px 20px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #414042;
}

.custom-select{
  background: #FAFAFB;
  border: 1px solid #E6E4E8;
  box-sizing: border-box;
  border-radius: 4px;
}

.error-input{
  border: 1px solid #f44336 !important;
}

.success-input{
  border: 1px solid #25b945 !important;
}

.error-input-message{
  font-weight: 300;
  font-size: 15px;
  color: #F44336;
}

.success-input-message{
  font-weight: 300;
  font-size: 15px;
  color: #25b945
}

.custom-btn{
  background: #22C9C7;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FCFCFC;
  padding: 14px 20px;
  border: none;
}

.nav-header .nav-link.active{
  background: #22C9C7 !important; 
  color: white !important;
}

.nav-header .nav-link:not(.active){
  color: #7D7C7F !important;
  background : white !important;
  border : none !important;
}


.nav-step{
  margin-top: 30px !important;
  background: #F5F4F6 !important;
}

.nav-step .nav-step-link:not(:last-child){
  margin-right: 25px;
}

.nav-step .nav-step-link:not(.done){
  background: #F5F4F6 !important;
}
.nav-step .nav-step-link{
  background: #9AD7D6 !important;
  border-radius: 8px !important;
  border-color: none;
}

.modal-backdrop{
  background: #22C9C7 !important;
  opacity: 1 !important;
}

.modal-header{
  border: none !important;
}
.modal-header button{
  color: #CFCDD1 !important;
}

.modal-content{
  border: none;
  border-radius: 12px;
}


.modal-body{
  height: 150px;
  text-align: center;
  padding-top: 0 !important;
}

.modal-body .text-header{
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #414042;
  margin-bottom: 10px !important;
}

.modal-body .text-sub-header{
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #414042;  
}

.modal-body .ok{
  width: 110px;
  background: #22C9C7;
  border-radius: 8px;
  display: block;
  text-align: center;
  margin: 0 auto;
  text-decoration: none !important;
  color: white !important;
  padding: 5px;
}

.module-header{
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 52px;
  color: #414042;
}

.version{
  font-size: 12px;
  font-weight: 300;
  color: #e0e0e0;
  position: absolute;
  bottom: 0;
  left: 25px;
}

.custom-progress{
  background : #E6E4E8;
  height: 10px !important;
}

.custom-progress-bar{
  background: #D4236D !important;
  border-radius: 9px !important;

}

.custom-progress-bar-safe{
  background : #22C9C7 !important;
}
.custom-progress-bar-danger{
  background : #FF6666 !important;
}

.modal-backdrop{
  background: #22C9C7 !important;
  opacity : .7 !important;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #9E9E9E;
  box-sizing: border-box;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background: #22C9C7;
  border-radius: 4px;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
  
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* SPINNER MODAL */


.modal-open {
  padding: 0 !important;
}

.modal-open-override{
  overflow: auto !important;
  padding-right: 0 !important;
}


.spinner-modal{
  z-index: 1500 !important;
}

.spinner-modal .modal-content{
  background: transparent !important;
  border: none !important;
}

.custom-spinner-border{
  color : #22c9c7 !important;
  position: absolute;
  top: 50%;
  left: 45%;
}

.btn-option{
  border-radius: 4px !important;
  background: transparent !important;
  color: #22c9c7 !important;
  font-size: 12px;
}

.btn-option:focus{
  background: #22c9c7 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.custom-dropdown-item{
  color: #7D7C7F !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none !important;
}

.custom-dropdown-item:focus{
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.custom-dropdown-right{
  top: 5px !important;
}

.custom-get-more-spinner{
  padding-top: 5px !important;
  display: block !important;
  margin: 0 auto !important;
  color : #22c9c7 !important;
}

.message-modal .modal-body{
  height : auto !important;
  border: none;
}

.message-modal .modal-dialog{
  max-width: 650px;
  min-width: 400px;
  width: 400px;
  z-index: 1050;
}

.message-modal .modal-content{
  border: none;
  padding: 1.75rem 1.75rem 3rem 3rem !important;
}


.translator-notification-modal .modal-body{
  height : auto !important;
  border: none;
}

.translator-notification-modal .modal-dialog{
  max-width: 750px;
  width: 750px;
  z-index: 1050;
}

.translator-notification-modal .modal-content{
  border: none;
  padding: 3rem !important;
}

.nowrap-nav{
  flex-wrap : nowrap !important;
  font-size: 11px;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #22c9c7;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
background: #22c9c7;
}



