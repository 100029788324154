.translator-modal-search{
    position: relative;
    width: 100%;
    flex: 1;
}

.translator-modal-search img{
    position: absolute;
    left: 20px;
    top: 13px;
    height: 15px;
    width: 15px;

}

.translator-modal-search-input{
    background: #FFFFFF;
    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #939097;
    padding: 16px 16px 16px 42px;
    height: 42px;
    width: 100%;
}

.translator-modal-search-input::placeholder{
    font-weight: 300;
}