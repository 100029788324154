
/* RIGHT MODAL */
.right-modal .modal-dialog {
    top: 0 !important;
    right: 0 !important;
    text-align: left !important;
    position: fixed !important;
    margin: auto !important;
    width: 580px !important;
    max-width: 580px !important;
    height: 100% !important;
    -webkit-transform: translate3d(0%, 0, 0) !important;
        -ms-transform: translate3d(0%, 0, 0) !important;
            -o-transform: translate3d(0%, 0, 0) !important;
            transform: translate3d(0%, 0, 0) !important;
}

.right-modal .modal-header{
    text-align : left !important;
    height: 70px;
    margin-right : 40px;
    margin-left: 40px;
    padding-top: 40px;
}

.right-modal .modal-content {
    height: 100% !important;
    min-height: 100% !important;
    border-radius :0 !important;
    border: none !important;
    /* padding: 30px; */
}

.right-modal .modal-body {
    /* padding: 15px 15px 80px !important; */
    padding: 0 !important;
    overflow-y: auto;
}

/*Right*/
.right-modal.fade .modal-dialog {
    right: 0 !important;
    top: 0 !important;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.right-modal.fade.in .modal-dialog {
    right: 0 !important;
}

.right-modal-header{
    font-style: "normal";
    font-weight: 500;
    font-size: "20px";
    line-height: "64px";
    color: "#464646";
    margin-bottom : "10px";
}

.right-modal .modal-body .nav-tabs{
    margin-top : 20px !important;
    margin-right : 40px;
    margin-left : 40px;
}


.right-modal .modal-body .nav-item{
    font-size: 12px;
    font-weight : 300;
}

.right-modal .modal-body .nav-header .nav-link.active{
    background: #22C9C7 !important;
    color: white !important;
    border : none !important;
}
  
.right-modal .modal-body .nav-header .nav-link:not(.active){
    color: #7D7C7F !important;
    border : none !important;
}

.modal .op-btn{
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    width: 100%;
    color: #FCFCFC;  
    text-transform : uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
}

/* DECLINE TASK MODAL */
.decline-task-modal .modal-body{
    height : 400px !important;
    border: none;
}

.decline-task-modal .modal-dialog{
    max-width: 650px;
}


.decline-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

/* ESCALATE TASK MODAL */
.escalate-task-modal .modal-body{
    height : auto !important;
    border: none;
}

.escalate-task-modal .modal-dialog{
    max-width: 650px;
}

.escalate-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

/* CONFIRMATION MODAL */
.confirmation-modal .modal-body{
    height : auto !important;
    border: none;
}

.confirmation-modal .modal-dialog{
    max-width: 650px;
    width: auto;
}

.confirmation-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.confirmation-modal .btn-ok{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border : none;
    margin-right: 15px;
}

.confirmation-modal .btn-cancel{
    border: 2px solid #FF6666;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FF6666;
    padding : 12px 16px;
    background: transparent;
}

.confirmation-modal .confirmation-header{
    font-size: 20px;
}

/* HIRED TRANSLATOR MODAL */
.hired-translator-modal .modal-body{
    text-align: left;
    height : auto !important;
    border: none;
}

.hired-translator-modal .modal-dialog{
    max-width: 650px;
    width: auto;
}

.hired-translator-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.hired-translator-details{
    padding-left: 40px;
    padding-right: 40px;
}

.hired-translator-modal .btn-chat{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border : none;
    flex : 1;
}

.hired-translator-modal .btn-decline{
    border: 2px solid #FF6666;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FF6666;
    padding : 12px 16px;
    background: transparent;
    flex : 1;
    margin-right: 15px;

}

/* MESSAGE PROMPT MODAL */
.message-prompt-modal .modal-body{
    height : auto !important;
    border: none;
}

.message-prompt-modal .modal-dialog{
    max-width: 650px;
    width: auto;
}

.message-prompt-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.message-prompt-modal .btn-ok{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border : none;
    margin-right: 15px;
}

/* COMPLETE TASK MODAL */
.complete-task-modal .modal-body{
    text-align: left;
    height : auto !important;
    border: none;
}

.complete-task-modal .modal-dialog{
    max-width: 650px;
    width: auto;
}

.complete-task-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.complete-task-details{
    padding-left: 40px;
    padding-right: 40px;
}

.complete-task-modal .btn-chat{
    background: #22C9C7;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    padding: 12px 16px;
    border : none;
    flex : 1;
}

.bold-text{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #414042;  
}

.right-modal .rm-customer-name{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #080708;
}

.right-modal .rm-customer-name img{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    margin-right: 15px;
}

.right-modal .op-rm-customer-name{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #080708;
}

.right-modal .op-rm-customer-name img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-right: 15px;
}

.right-modal .escalation-container{
    margin-bottom : 16px;
}

.right-modal .escalation-container .escalation-details{
    position: relative;
    background: #FAFAFB;
    border-radius: 12px;
    padding: 24px;
}

.right-modal .escalation-details .message-icon{
    width: 23px;
    height: 18px;
    position: absolute;
    top: 26px;
    right: 21px;
    cursor : pointer;
}

.right-modal .escalator-details .display-photo{
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 100%;
}

.right-modal .escalator-details .escalator-name{
    display: block;
    margin-left: 100px;
    color: #414042;
    font-size: 16px;
}

.right-modal .escalator-details .escalator-type{
    margin-left: 100px;
    color: #939097;
    font-size: 12px;
}

.right-modal .escalations{
    margin-top: 50px;
}

.right-modal .escalations .esc-letter{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #414042;
}