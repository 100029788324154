.dashboard select{
    border: none;
    font-size: 12px;
}

.stat-row{
    display: flex;
    width: 100%;
}

.stat-box-header{
    font-style: normal;
    font-weight: bold;
    font-size: .85rem;
    line-height: 28px;
    color: #414042;
}

.stat-box{
    width: 45%;
    min-width: 45%;
    flex : 1;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.08);
    padding: 24px;
    margin: 20px;
}

.stat-percentage{
    display: flex;
}

.stat-percentage-details{
    flex: 1;
}

.stat-percentage-text{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #939097;
}

.stat-percentage-value{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #414042;
    display: block;
    margin-left: 20px;
}

.task-stat-total{
    position: absolute;
    top: 40%;
    left: 43%;
    text-align: center;
}

.task-stat-total-header{
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #000000;
    display: block;
}

.task-stat-total-sub-header{
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #939097; 
}

.dashboard-checkbox-container{
    display: block;
    position: relative;
    padding-left: 25px;
    padding-top: 5px;
    margin-bottom: 0;
    margin-left: 15px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    user-select: none;
}

.checkbox-container input:checked ~ .essay-checkmark{
    background : #D4236D
}

.checkbox-container input:checked ~ .presentation-checkmark{
    background :#22C9C7;
}

.checkbox-container input:checked ~ .dis-checkmark{
    background : #5D31AF;
}

.dashboard-date-range-filter{
    display: flex;
    background: transparent;
    /* gray/40 */

    border: 1px solid #E6E4E8;
    box-sizing: border-box;
    border-radius: 4px;
    height: auto;
    padding: 10px;
    margin-left: 24px;
    font-size: 16px;
    line-height: 28px;
    align-items: center;
    color: #414042;
}

.dashboard-date-range-filter input{
    height: 20px;
    width: 88px !important;
    margin-left: 10px;
    background: #ffffff;
    color: #414042;
    padding-left: 0 !important;
    font-size: 12px;
    text-indent: 10px;
    text-align: center;
}

.dashboard-date-range-filter .date-range-buttons{
    background : transparent;
    border: 1px solid #cecece;
    border-radius: 4px;
    margin-left: 10px;
    font-size: 12px;
}

.dashboard-date-range-filter .date-range-buttons-apply{
    background : #22c9c7 !important;
    border: none;
    border-radius: 4px;
    margin-left: 15px;
    font-size: 12px;
    color: #ffffff;
}

.stat-chart-area{
    display: flex;
    width: 100%;
}

.stat-chart-area .button-container{
    position : relative;
    width: 20px;
}

.stat-chart-area button{
    position: absolute;
    top: 50%;
    border-radius: 4px;
    border: 1px solid #CFCDD1;
    background : transparent;
    color : #7AB7B6;
}

.year-label{
    color : #939097;
}

.non-year-label{
    color : #939097;
    float: right;
}

.joined-label{
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #414042;
}