.filter-translators{
    border: 2px solid #22C9C7;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #22C9C7;
    padding: 8px 20px;
    background: transparent;
    height: 43px;
    margin-left: 20px;
}

.translator-item{
    background: #FAFAFB;
    border-radius: 12px;
    padding: 24px 24px;
    margin-bottom: 20px;
    height: auto;
    display: flex;
}

.translator-item .translator-item-left .main-details{
    margin-left: 75px;
}

.translator-item .translator-item-left{
    flex: 1;
    position: relative;
    max-width: 25%;
    min-width: 25%;
    border-right: 1px solid #E9E9EA;
}

.translator-item .translator-item-left .display-photo{
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
}

.translator-item .translator-item-left .tr-rating img{
    width: 15px;
    height: 15px;
}

.translator-item .translator-item-left .tr-rating img:not(:first-child){
    margin-left: 7px;
}

.translator-item .translator-item-left .tr-name{
    color : #a5a1aa;
    font-size: 14px;
    display: block;
}

.translator-item .translator-item-left .tr-presence{
    color: #939097;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.translator-item .translator-item-left .tr-presence-offline{
    color: #939097;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.translator-item .translator-item-left .tr-presence-online{
    color: #66BB6A;
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.translator-item .translator-item-right{
    margin-left: 20px;
    max-width: 80%;
    width: 80%;
}

.translator-item .translator-item-right .translator{
    position: relative;
}

.translator-item .translator-item-right .translator .actions{
    position: absolute;
    top: 0;
    right: 25px;
    display: flex;
    width: 30%;
}

.translator-item .translator-item-right .translator .actions .hire-translator{
    background: #22C9C7;
    border-radius: 8px;
    border: none;
    width: 90%;
    color: #FCFCFC;  
    text-transform : uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
}

.translator-item .translator-item-right .translator .details{
    display : flex;
    width: 70%;
}

.translator-item .translator-item-right .translator .details .tr-label{
    color : #939097;
    font-weight: 300;
    font-size: 12px;
    align-items: center;
    display: block;
}

.translator-item .translator-item-right .translator .details .tr-value{
    font-weight: bold;
    font-size: 12px;
    color: #414042;
    text-transform : capitalize;
}

.translator-item .translator-item-right .task-details{
    margin-top: 24px;
    margin-bottom: 10px;
}

.translator-item .translator-item-right .task-details p{
    font-size: 15px;
    word-break: break-word;
    color: #676A6C;
    font-weight: 400;
    font-size: 16px;
}

.translator-item .translator-item-right .task-details p:before{
    content : "Description: "
}

/* TRANSLATOR FLOATING FILTER */
.translator-dropdown-filter{
    position: relative;
    z-index: 100;
}
.translator-floating-filter{
    width: auto;
    max-width: 1200px;
    background : white;
    padding : 24px 24px 24px 0;
    left: -865px;
    position: absolute;
    top: 48px;
    box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
}
.translator-floating-filter .filter-options-header{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #080708;
    margin-bottom: 30px;
    display: block;
}
.translator-floating-filter .filter-option-list{
    list-style : none;
    padding-left: 0;
}
.translator-floating-filter .filter-option-list li label{
    font-size: 11px !important;
}
.translator-floating-filter .filters{
    display: flex;
}
.translator-floating-filter .nonflex-filters{
    display: block;
}
.translator-floating-filter .filter-options{
    margin-left: 38px;
    min-width: 200px;
}

.filter-no-translator{
    padding-top: 50px;
    text-align: center;
}

.filter-no-translator img{
    width: 30%;
    height: 30%;
}

.filter-no-translator-text{
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #414042;
}

.filter-no-translator-button{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FCFCFC;
    background: #22C9C7;
    border-radius: 8px;
    padding: 16px 20px;
    border: none;
}

.fire-translator-modal .modal-body{
    height : auto !important;
    border: none;
}

.fire-translator-modal .modal-dialog{
    max-width: 650px;
}

.fire-translator-modal .modal-content{
    border: none;
    padding: 1rem 3rem 3rem 3rem !important;
}

.translator-table-div{
    background: #FFFFFF;
    box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.translator-table-div table{
    width: 100%;
}

.translator-table-div table th{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #939097;
    padding: 15px;
    background : #ffffff;
}


.translator-table-div table tr td{
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #080708; 
    padding: 15px;
    vertical-align: top;
}

.translator-table-div table tr{
    cursor: pointer;
}
.translator-table-div table tr:nth-child(odd){
    background: #FAFAFB;
}

.translator-type{
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 4px 16px;
    display: block;
    width: 8rem;
}

.translator-price{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #414042;
}